
<template>
  <div class="main-content">
    <breadcumb :page="'Reports'" :folder="'Pages'" />

    <div class="row">
      <div class="col-md-12">
         <ul>
           <li><a href="/apps/dailySalesReport" style="text-decoration: underline;font-weight: bolder;">Daily Sales Report</a></li>
           <li><a href="/apps/weeklySalesReport" style="text-decoration: underline;font-weight: bolder;">Weekly Sales Report</a></li>
           <li><a href="/apps/monthlySalesReport" style="text-decoration: underline;font-weight: bolder;">Monthly Sales Report</a></li>
           <li><a href="#" style="text-decoration: underline;font-weight: bolder;">Production Report</a></li>
         </ul>
      </div>
    </div>


  </div>
</template>
<script>
export default {
  data() {
    return {

    };
  }
};
</script>
